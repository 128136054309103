


$(document).ready(function(){ 
  $(window).scroll(function(){ 
      if ($(this).scrollTop() > 300) { 
          $('#scroll').fadeIn(); 
      } else { 
          $('#scroll').fadeOut(); 
      } 
  }); 
  $('#scroll').click(function(){ 
      $("html, body").animate({ scrollTop: 0 }, 1200); 
      return false; 
  }); 
});





$(document).ready( function () {
  $('#table_id').DataTable({
    dom: 'Bfrtip',
    buttons: [
        'copy', 'csv', 'excel', 'pdf', 'print'
    ]
  });

  $('#table_id_2').DataTable({
    dom: 'Bfrtip',
    buttons: [
        'copy', 'csv', 'excel', 'pdf', 'print'
    ]
  });
} );